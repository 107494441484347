define("ember-ghost-casper-blog/templates/components/image-attribution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y8YSea3l",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"meta\",\"attributionLink\"]]],null,{\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"site-nav cover-attribution\"],[11,\"style\",\"flex-direction: row-reverse;\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[28,[[23,[\"meta\",\"attributionLink\"]]]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"style\",\"height:12px;width:auto;position:relative;vertical-align:middle;top:-1px;fill:white;\"],[11,\"viewBox\",\"0 0 32 32\"],[9],[0,\"\\n        \"],[7,\"title\"],[9],[0,\"unsplash-logo\"],[10],[0,\"\\n        \"],[7,\"path\"],[11,\"d\",\"M20.8 18.1c0 2.7-2.2 4.8-4.8 4.8s-4.8-2.1-4.8-4.8c0-2.7 2.2-4.8 4.8-4.8 2.7.1 4.8 2.2 4.8 4.8zm11.2-7.4v14.9c0 2.3-1.9 4.3-4.3 4.3h-23.4c-2.4 0-4.3-1.9-4.3-4.3v-15c0-2.3 1.9-4.3 4.3-4.3h3.7l.8-2.3c.4-1.1 1.7-2 2.9-2h8.6c1.2 0 2.5.9 2.9 2l.8 2.4h3.7c2.4 0 4.3 1.9 4.3 4.3zm-8.6 7.5c0-4.1-3.3-7.5-7.5-7.5-4.1 0-7.5 3.4-7.5 7.5s3.3 7.5 7.5 7.5c4.2-.1 7.5-3.4 7.5-7.5z\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"meta\",\"attribution\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[23,[\"meta\",\"attribution\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/components/image-attribution.hbs"
    }
  });

  _exports.default = _default;
});