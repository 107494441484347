define("ember-ghost-casper-blog/routes/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    classNames: ["page-template"],

    model(params) {
      return this.store.queryRecord('page', {
        path: params.id
      });
    }

  });

  _exports.default = _default;
});