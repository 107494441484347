define("ember-ghost-casper-blog/routes/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    classNames: ["post-template"],

    model(params) {
      return Ember.RSVP.hash({
        post: this.store.queryRecord('content', {
          path: params.id
        }),
        posts: this.store.query('content', {
          path: 'content'
        })
      }).then(result => {
        return Ember.get(result, 'post.author').then(author => this.store.findRecord('author', author.id)).then(() => result);
      });
    }

  });

  _exports.default = _default;
});