define("ember-ghost-casper-blog/templates/components/site-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NGDSVdod",
    "block": "{\"symbols\":[\"navigation\"],\"statements\":[[4,\"each\",[[23,[\"blog\",\"navigation\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[28,[\"nav-\",[22,1,[\"label\"]]]]],[11,\"role\",\"menuitem\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"id\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",[[22,1,[\"route\"]],[22,1,[\"id\"]]],null,{\"statements\":[[0,\" \"],[1,[22,1,[\"label\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,1,[\"route\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",[[22,1,[\"route\"]]],null,{\"statements\":[[0,\" \"],[1,[22,1,[\"label\"]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,1,[\"link\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"href\",[28,[[22,1,[\"link\"]]]]],[9],[0,\" \"],[1,[22,1,[\"label\"]],false],[0,\" \"],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/components/site-navigation.hbs"
    }
  });

  _exports.default = _default;
});