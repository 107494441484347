define("ember-ghost-casper-blog/components/post-card", ["exports", "ember-ghost-casper-template/helpers/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blog: Ember.inject.service(),
    router: Ember.inject.service(),
    url: Ember.inject.service(),
    tagName: '',
    postStyle: Ember.computed('', function () {
      return Ember.String.htmlSafe(`background-image: url(${(0, _url.url)([this.post.image])})`);
    })
  });

  _exports.default = _default;
});