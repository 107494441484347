define("ember-ghost-casper-blog/components/site-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blog: Ember.inject.service(),
    tagName: 'ul',
    classNames: ['nav']
  });

  _exports.default = _default;
});