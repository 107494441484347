define("ember-ghost-casper-blog/components/markdown-to-html", ["exports", "ember-cli-showdown/components/markdown-to-html"], function (_exports, _markdownToHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _markdownToHtml.default.extend({
    classNames: ['kg-card-markdown']
  });

  _exports.default = _default;
});