define("ember-ghost-casper-blog/components/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    coverImageStyle: Ember.computed('feature_image', function () {
      return `background-image: url(${this.feature_image})`;
    })
  });

  _exports.default = _default;
});