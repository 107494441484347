define("ember-ghost-casper-blog/components/link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    click() {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
    }

  });

  _exports.default = _default;
});