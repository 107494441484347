define("ember-ghost-casper-blog/initializers/routes", ["exports", "ember-ghost-casper-blog/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _router.default.map(function () {
      this.route('post', {
        path: ':id'
      });
      this.route('page', {
        path: '/page/:id'
      });
      this.route('author', {
        path: '/author/:id'
      });
      this.route('tag', {
        path: '/tag/:id'
      });
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});