define("ember-ghost-casper-blog/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    blog: Ember.inject.service(),
    url: Ember.inject.service(),
    coverImageStyle: Ember.computed('blog.coverImage', function () {
      return Ember.String.htmlSafe(`background-image: url(${Ember.get(this, 'url.prefix')}${Ember.get(this, 'blog.coverImage')})`);
    })
  });

  _exports.default = _default;
});