define("ember-ghost-casper-blog/components/floating-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blog: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    value: 0,
    classNameBindings: [':floating-header', 'floating:floating-active'],

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      Ember.set(this, 'lastScrollY', window.scrollY);
      Ember.set(this, 'lastWindowHeight', window.innerHeight);
      Ember.set(this, 'lastDocumentHeight', Ember.$(document).height());
      this.update();
    },

    didInsertElement() {
      let scrollEvent = () => {
        Ember.set(this, 'lastScrollY', window.scrollY);
        this.requestTick();
      };

      Ember.set(this, 'scrollEvent', scrollEvent);
      window.addEventListener('scroll', scrollEvent, {
        passive: true
      });

      let resizeEvent = () => {
        Ember.set(this, 'lastWindowHeight', window.innerHeight);
        Ember.set(this, 'lastDocumentHeight', Ember.$(document).height());
        this.requestTick();
      };

      Ember.set(this, 'resizeEvent', resizeEvent);
      window.addEventListener('resize', resizeEvent, false);
    },

    didDestroyElement() {
      let scrollEvent = this.get('scrollEvent');

      if (scrollEvent) {
        window.removeEventListener('scroll', scrollEvent);
        Ember.set(this, 'scrollEvent', null);
      }

      let resizeEvent = this.get('resizeEvent');

      if (resizeEvent) {
        window.removeEventListener('resize', resizeEvent);
        Ember.set(this, 'resizeEvent', null);
      }
    },

    requestTick() {
      if (!this.ticking) {
        requestAnimationFrame(() => {
          this.update();
        });
      }

      Ember.set(this, 'ticking', true);
    },

    update() {
      // debugger
      var title = document.querySelector('.post-full-title');
      var lastScrollY = this.lastScrollY;
      var trigger = title.getBoundingClientRect().top + window.scrollY;
      var triggerOffset = title.offsetHeight + 35;
      var progressMax = this.lastDocumentHeight - this.lastWindowHeight; // show/hide floating header

      if (lastScrollY >= trigger + triggerOffset) {
        Ember.set(this, 'floating', true);
      } else {
        Ember.set(this, 'floating', false);
      }

      Ember.set(this, 'max', progressMax);
      Ember.set(this, 'value', this.lastScrollY);
      Ember.set(this, 'ticking', false);
    }

  });

  _exports.default = _default;
});