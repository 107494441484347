define("ember-ghost-casper-blog/models/page", ["exports", "ember-ghost-casper-blog/models/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _content.default.extend({});

  _exports.default = _default;
});