define("ember-ghost-casper-blog/helpers/encode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.encode = encode;
  _exports.default = void 0;

  function encode(params
  /*, hash*/
  ) {
    return encodeURIComponent(params);
  }

  var _default = Ember.Helper.helper(encode);

  _exports.default = _default;
});