define("ember-ghost-casper-blog/templates/components/svg-icons/infinity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iUa2w+DC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 24 24\"],[9],[7,\"path\"],[11,\"d\",\"M13 14.5s2 3 5 3 5.5-2.463 5.5-5.5S21 6.5 18 6.5c-5 0-7 11-12 11C2.962 17.5.5 15.037.5 12S3 6.5 6 6.5s4.5 3.5 4.5 3.5\"],[9],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/components/svg-icons/infinity.hbs"
    }
  });

  _exports.default = _default;
});