define("ember-ghost-casper-blog/routes/author", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    classNames: ["author-template"],

    model(params) {
      // load content first for ember-data autopopulation
      return this.store.query('content', {
        path: 'content'
      }).then(() => {
        return this.store.findRecord('author', params.id);
      });
    }

  });

  _exports.default = _default;
});