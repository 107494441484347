define("ember-ghost-casper-blog/services/url", ["exports", "ember-ghost-casper-blog/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    prefix: Ember.computed(function () {
      let prefix = _environment.default.apiHost || '';

      if (prefix && _environment.default.apiNamespace) {
        prefix += `/${_environment.default.apiNamespace}`;
      } else if (prefix) {
        prefix += _environment.default.apiNamespace;
      }

      return prefix;
    })
  });

  _exports.default = _default;
});