define("ember-ghost-casper-blog/helpers/excerpt", ["exports", "ember-ghost/helpers/excerpt"], function (_exports, _excerpt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _excerpt.default;
    }
  });
  Object.defineProperty(_exports, "excerpt", {
    enumerable: true,
    get: function () {
      return _excerpt.excerpt;
    }
  });
  Object.defineProperty(_exports, "getExcerpt", {
    enumerable: true,
    get: function () {
      return _excerpt.getExcerpt;
    }
  });
});