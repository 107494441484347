define("ember-ghost-casper-blog/controllers/author", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    coverImageStyle: Ember.computed('model.coverImage', function () {
      return `background-image: url(${Ember.get(this, 'model.coverImage')})`;
    })
  });

  _exports.default = _default;
});