define("ember-ghost-casper-blog/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "huJnsgIg",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"header\"],[11,\"class\",\"site-header outer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n        \"],[1,[21,\"site-nav\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"main\"],[11,\"id\",\"site-main\"],[11,\"class\",\"site-main outer\"],[11,\"role\",\"main\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n\\n        \"],[7,\"article\"],[12,\"class\",[28,[\"post-full page \",[27,\"unless\",[[23,[\"model\",\"image\"]],\"no-image\"],null]]]],[9],[0,\"\\n\\n            \"],[7,\"header\"],[11,\"class\",\"post-full-header\"],[9],[0,\"\\n                \"],[7,\"h1\"],[11,\"class\",\"post-full-title\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"image\"]]],null,{\"statements\":[[0,\"            \"],[7,\"figure\"],[11,\"class\",\"post-full-image\"],[12,\"style\",[28,[\"background-image: url(\",[23,[\"model\",\"image\"]],\")\"]]],[9],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"section\"],[11,\"class\",\"post-full-content\"],[9],[0,\"\\n                \"],[1,[27,\"markdown-to-html\",[[23,[\"model\",\"content\"]]],null],false],[0,\"\\n            \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/page.hbs"
    }
  });

  _exports.default = _default;
});