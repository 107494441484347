define("ember-ghost-casper-blog/templates/components/svg-icons/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70qWaNSX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",\"24\"],[11,\"height\",\"24\"],[11,\"viewBox\",\"0 0 24 24\"],[11,\"stroke\",\"#000\"],[11,\"stroke-linecap\",\"round\"],[11,\"stroke-linejoin\",\"round\"],[11,\"stroke-miterlimit\",\"10\"],[11,\"fill\",\"none\"],[9],[7,\"path\"],[11,\"d\",\"M19.5 8c0 4.144-7.5 15.5-7.5 15.5S4.5 12.144 4.5 8C4.5 3.858 7.857.5 12 .5c4.142 0 7.5 3.358 7.5 7.5z\"],[9],[10],[7,\"circle\"],[11,\"cx\",\"12\"],[11,\"cy\",\"8\"],[11,\"r\",\"3\"],[9],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/components/svg-icons/location.hbs"
    }
  });

  _exports.default = _default;
});