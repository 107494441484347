define("ember-ghost-casper-blog/controllers/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    coverImageStyle: Ember.computed('tag.feature_image', function () {
      return `background-image: url(${Ember.get(this, 'tag.feature_image')})`;
    })
  });

  _exports.default = _default;
});