define("ember-ghost-casper-blog/models/author", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    coverImage: _emberData.default.attr('string'),
    coverMeta: _emberData.default.attr(),
    content: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    twitter: _emberData.default.attr('string'),
    facebook: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    posts: _emberData.default.hasMany('content')
  });

  _exports.default = _default;
});