define("ember-ghost-casper-blog/templates/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U+wmMpgd",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[0,\"\\n\"],[7,\"header\"],[12,\"class\",[28,[\"site-header outer \",[27,\"if\",[[23,[\"tag\",\"feature_image\"]],\"no-cover\"],null]]]],[12,\"style\",[27,\"if\",[[23,[\"tag\",\"feature_image\"]],[23,[\"coverImageStyle\"]]],null]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n        \"],[1,[21,\"site-nav\"],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"site-header-content\"],[9],[0,\"\\n            \"],[7,\"h1\"],[11,\"class\",\"site-title\"],[9],[1,[27,\"titleize\",[[23,[\"model\",\"tag\"]]],null],false],[10],[0,\"\\n            \"],[7,\"h2\"],[11,\"class\",\"site-description\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"tag\",\"description\"]]],null,{\"statements\":[[0,\"                    \"],[1,[23,[\"tag\",\"description\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    A collection of \"],[1,[23,[\"model\",\"posts\",\"length\"]],false],[0,\" posts\\n\"]],\"parameters\":[]}],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"main\"],[11,\"id\",\"site-main\"],[11,\"class\",\"site-main outer\"],[11,\"role\",\"main\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"post-feed\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"posts\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"post-card\",null,[[\"post\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ghost-casper-blog/templates/tag.hbs"
    }
  });

  _exports.default = _default;
});