define("ember-ghost-casper-blog/components/site-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blog: Ember.inject.service(),
    router: Ember.inject.service(),
    url: Ember.inject.service(),
    tagName: '',
    rssFeed: Ember.computed('blog.host', function () {
      return `https://feedly.com/i/subscription/feed${encodeURIComponent('/' + Ember.get(this, 'blog.host') + '/rss.xml')}`;
    })
  });

  _exports.default = _default;
});