define("ember-ghost-casper-blog/services/blog", ["exports", "ember-ghost-casper-blog/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function configParam(param) {
    return Ember.computed(function () {
      return Ember.get(_environment.default, `blog.${param}`);
    });
  }

  var _default = Ember.Service.extend({
    title: configParam('title'),
    description: configParam('description'),
    logo: configParam('logo'),
    coverImage: configParam('coverImage'),
    coverMeta: configParam('coverMeta'),
    navigation: configParam('navigation'),
    twitter: configParam('twitter'),
    facebook: configParam('facebook'),
    host: configParam('host')
  });

  _exports.default = _default;
});