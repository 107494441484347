define("ember-ghost-casper-blog/helpers/url", ["exports", "ember-ghost/helpers/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _url.default;
    }
  });
  Object.defineProperty(_exports, "url", {
    enumerable: true,
    get: function () {
      return _url.url;
    }
  });
});