define("ember-ghost-casper-blog/routes/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        tag: params.id,
        posts: this.store.query('content', {
          path: 'content'
        }).then(posts => posts.filter(post => {
          if (Ember.get(post, 'tags')) {
            return Ember.get(post, 'tags').includes(params.id);
          }
        }))
      });
    }

  });

  _exports.default = _default;
});